import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withRouter } from 'router'
import { setMainVisible, setLoginVisible } from 'popups'

import i18n from 'i18n'
import { getConfig } from 'env'

// import classNames from 'classnames'
// import store from 'store'
// import { find } from 'lodash'

import styles from './style.module.scss'

const mobileMenuKey = '__mobileMenu__'

const mapStateToProps = ({ settings, user }) => ({
  logo: settings.logo,
  isSidebarOpen: settings.isSidebarOpen,
  menuColor: settings.menuColor,
  role: user.role,
  user,
})

const Menu = ({
  dispatch,
  menuData = [],
  naviHide,
  isSidebarOpen,
  // location: { pathname },

  // menuColor,
  // logo,
  role,
  user,
}) => {
  // const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])

  // useEffect(() => {
  //   applySelectedKeys()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pathname, menuData])

  // const applySelectedKeys = () => {
  //   const flattenItems = (items, key) =>
  //     items.reduce((flattenedItems, item) => {
  //       flattenedItems.push(item)
  //       if (Array.isArray(item[key])) {
  //         return flattenedItems.concat(flattenItems(item[key], key))
  //       }
  //       return flattenedItems
  //     }, [])
  //   const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname])
  //   setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  // }

  // const handleClick = e => {
  //   store.set('app.menu.selectedKeys', [e.key])
  //   setSelectedKeys([e.key])
  // }
  const [selected, setSelected] = useState({})

  const toggleMenuKey = (key, value, e, parentKey) => {
    const newSelected = {}

    let ret = value

    if (selected[key] != null && (value == null || value === false)) {
      let menuChain = selected[key]

      const pos = menuChain.indexOf(key)
      menuChain = menuChain.slice(0, pos)
      menuChain.forEach((chainKey) => {
        newSelected[chainKey] = menuChain
      })

      ret = false
    } else if (newSelected[key] == null && (value == null || value === true)) {
      const menuChain = selected[parentKey]
      selected[key] = menuChain != null ? menuChain : []
      selected[key].push(key)

      selected[key].forEach((chainKey) => {
        newSelected[chainKey] = selected[key]
      })
      ret = true
    }

    setSelected(newSelected)

    return ret
  }

  const isSelectedMenu = (key) => {
    return selected[key] != null
  }

  const closeAllMenu = (e) => {
    console.log(e)
    let isMenu = false
    if (e != null) {
      let elem = e.target
      while (elem != null) {
        const { classList } = elem

        if (classList.contains('menu-item') === true) {
          isMenu = true
          break
        } else if (
          classList.contains('dropdown-action') === true ||
          classList.contains('main-menu') === true
        ) {
          isMenu = true
          return
        }
        elem = elem.parentElement
      }
    }
    const selectedKeys = Object.keys(selected)
    if (selectedKeys.length > 1 || selected[mobileMenuKey] == null) {
      const newSelected = {}
      selectedKeys.some((key) => {
        if (key === mobileMenuKey) {
          newSelected[key] = [key]
          return true
        }
        return false
      })
      setSelected(newSelected)
      if (e != null && isMenu === true) {
        e.preventDefault()
        e.stopPropagation()
      }
    }
  }

  // const getMenuKey = (key) => {
  //   const pos = isSelectedMenu(key);
  //   return (pos > -1);
  // }

  useEffect(() => {
    // checkEffect(naviHide)
    document.addEventListener('click', closeAllMenu)

    return () => {
      document.removeEventListener('click', closeAllMenu)
      // checkEffect(naviHide)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [naviHide, selected])

  // const checkEffect = isNaviHide => {
  //   console.log('naviHide = ', isNaviHide, hideEvents, selected)
  //   Object.entries(hideEvents).forEach(entry => {
  //     if (entry[0] !== mobileMenuKey) {
  //       entry[1]()
  //     }
  //   })
  // }

  const isMobileBack = (e, key) => {
    // const isSelected = getMenuKey(key);
    if (key === mobileMenuKey) {
      return e.target.getAttribute('href') == null
    }
    return false
  }

  const handleExtClick = (e, key, value, parentKey) => {
    if (e != null) {
      console.log(e.currentTarget, e.target.parentElement)
    }
    console.log(parentKey)
    // const next = e.currentTarget.nextElementSibling;
    if (key === 'settings') {
      dispatch({
        type: 'settings/CHANGE_SETTING',
        payload: {
          setting: 'isSidebarOpen',
          value: !isSidebarOpen,
        },
      })
      return
    }

    toggleMenuKey(key, value, e, parentKey)
  }

  const mobileExtClick = (e, key, value) => {
    // const isSelected = getMenuKey(key);
    if (isMobileBack(e, key) === true) {
      return
    }
    handleExtClick(e, key, value)
  }

  // const handleClick = (e, item) => {
  // }

  const handleKeyDown = () => {}

  const generateMenuItems = () => {
    let dividerKeyIdx = 0

    const generateItem = (item, isSubItem) => {
      const { key, title, url, icon, /* disabled, */ count } = item

      let itemContent = ''

      if (item.authorized != null) {
        const isLogin = role != null && role.length > 0
        if (item.authorized === false && isLogin === true) {
          return null
        }
        if (item.authorized === true && isLogin === false) {
          return null
        }
      }

      if (item.category) {
        return null
      }
      const imgTag = item.imgSrc ? <img src={item.imgSrc} alt={item.title} /> : ''

      if (item.url) {
        itemContent =
          item.target != null ? (
            <a href={url} target={item.target} rel="noopener noreferrer">
              {imgTag}
              {icon && <span className={`${icon} ${styles.icon}`} />}
              <span className={styles.title}>{title}</span>
              {count && <span className="badge badge-success ml-2">{count}</span>}
            </a>
          ) : (
            <Link to={url}>
              {imgTag}
              {icon && <span className={`${icon} ${styles.icon}`} />}
              <span className={styles.title}>{title}</span>
              {count && <span className="badge badge-success ml-2">{count}</span>}
            </Link>
          )
      } else {
        itemContent = (
          <a
            href="#"
            onClick={(e) => {
              handleExtClick(e, key)
            }}
          >
            {imgTag}
            {icon && <span className={`${icon} ${styles.icon}`} />}
            <span className={styles.title}>{title}</span>
            {count && <span className="badge badge-success ml-2">{count}</span>}
          </a>
        )
      }

      if (isSubItem === true) {
        return (
          <li className="menu-item" key={key}>
            {itemContent}
          </li>
        )
      }

      return (
        <li key={key}>
          <div>{itemContent}</div>
        </li>
      )
    }

    const generateSubmenu = (items, parentKey) =>
      items.map((menuItem) => {
        if (menuItem.divider != null) {
          dividerKeyIdx += 1
          return <li className="divider" key={dividerKeyIdx} />
        }
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
              <span className={styles.title}>{menuItem.title}</span>
              {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
            </span>
          )
          const isSelected = isSelectedMenu(menuItem.key)
          return (
            <li key={menuItem.key}>
              <div
                className={
                  menuItem.children && menuItem.children.length > 0 ? 'dropdown-action' : ''
                }
                role="button"
                tabIndex="-1"
                onClick={(e) => {
                  handleExtClick(e, menuItem.key, null, parentKey)
                }}
                onKeyDown={handleKeyDown}
              >
                {subMenuTitle}
              </div>
              <div className="subMenu">
                <div className={`main-menu menu-sm ${isSelected ? '' : 'hidden'}`}>
                  <ul className="munu-group">{generateSubmenu(menuItem.children, menuItem.key)}</ul>
                </div>
              </div>
            </li>
          )
        }
        return generateItem(menuItem, true)
      })
    return menuData.map((menuItem, idx) => {
      if (menuData.authorized != null) {
        const isLogin = role != null && role.length > 0
        if (menuData.authorized === false && isLogin === true) {
          return null
        }
        if (menuData.authorized === true && isLogin === false) {
          return null
        }
      }
      if (menuItem.divider != null) {
        dividerKeyIdx += 1
        return <li className="divider" key={dividerKeyIdx} />
      }
      if (menuItem.roles && !menuItem.roles.includes(role)) {
        return null
      }
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            {menuItem.icon && <span className={`${menuItem.icon} ${styles.icon}`} />}
            <span className={styles.title}>{menuItem.title}</span>
            {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
          </span>
        )
        const hasChild = menuItem.children && menuItem.children.length > 0

        const isSelected = isSelectedMenu(menuItem.key)

        return (
          <li key={menuItem.key}>
            <div
              className={hasChild ? 'dropdown-action' : ''}
              role="button"
              tabIndex="-1"
              onClick={(e) => {
                handleExtClick(e, menuItem.key)
              }}
              onKeyDown={handleKeyDown}
            >
              {subMenuTitle}
            </div>
            <div className={`main-menu menu-sm ${isSelected ? '' : 'hidden'}`}>
              <ul className="munu-group">{generateSubmenu(menuItem.children, menuItem.key)}</ul>
            </div>
          </li>
        )
      }
      return generateItem(menuItem, true, idx)
    })
  }

  const generateMobileMenuItems = () => {
    const generateMobileSubMenuItems = (menuItems) => {
      return menuItems.map((menuItem) => {
        if (menuItem.divider != null) {
          return null
        }
        const imgSrc = menuItem.imgSrcMobile ? menuItem.imgSrcMobile : menuItem.imgSrc
        const imgTag = imgSrc ? <img src={imgSrc} alt={menuItem.title} /> : ''
        return (
          <li className="menu-item" key={menuItem.key}>
            <Link to={menuItem.url}>
              {imgTag}
              {menuItem.title}
            </Link>
          </li>
        )
      })
    }

    return menuData.map((menuItem) => {
      if (menuData.authorized != null) {
        const isLogin = role != null && role.length > 0
        if (menuData.authorized === false && isLogin === true) {
          return null
        }
        if (menuData.authorized === true && isLogin === false) {
          return null
        }
      }
      if (menuItem.divider != null) {
        return null
      }
      const imgSrc = menuItem.imgSrcMobile ? menuItem.imgSrcMobile : menuItem.imgSrc
      const imgTag = imgSrc ? <img src={imgSrc} alt={menuItem.title} /> : ''

      if (menuItem.children && menuItem.children.length > 0) {
        return (
          <React.Fragment key={menuItem.key}>
            <div className="sub-label">
              {imgTag}
              {menuItem.title}
            </div>
            <div className="main-menu">
              <ul className="munu-group">{generateMobileSubMenuItems(menuItem.children)}</ul>
            </div>
          </React.Fragment>
        )
      }
      if (menuItem.url) {
        return (
          <Link className="menu-link" to={menuItem.url} key={menuItem.key}>
            {imgTag}
            {menuItem.title}
          </Link>
        )
      }
      return (
        <a
          href="#"
          key={menuItem.key}
          onClick={(e) => {
            handleExtClick(e, menuItem.key)
          }}
        >
          {imgTag}
          <span className={styles.title}>{menuItem.title}</span>
        </a>
      )
    })
  }

  const logout = (e) => {
    dispatch({
      type: 'user/LOGOUT',
    })
    if (e != null) {
      e.preventDefault()
    }
  }

  const isStaticMode = process.env.REACT_APP_MODE === 'static'

  let userUI = ''
  let mobileUserUI = ''

  const isMobileSelected = isSelectedMenu(mobileMenuKey)

  if (isMobileSelected === true) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = ''
  }

  const openLogin = () => {
    closeAllMenu()
    // setLoginVisible(true, 'login')
    setLoginVisible(true, 'loginWithEmail')
  }

  if (isStaticMode === false) {
    if (role != null && role.length > 0) {
      const isSelected = isSelectedMenu('userMenu')

      const openMain = (e) => {
        if (isMobileSelected === true) {
          handleExtClick(null, mobileMenuKey, false)
        } else {
          closeAllMenu()
        }
        const defaultPage = getConfig('defaultPage');
        setMainVisible(true, defaultPage);
        if (e != null) {
          e.preventDefault()
        }
      }

      const imgURL = user.avatar

      const img =
        imgURL != null && imgURL.length > 0 ? (
          <img src={imgURL} alt="avatar" />
        ) : (
          <span className="fe fe-user" />
        )

      // const str = i18n.t('menu.user.usermenu');
      const str = (
        <div className={styles.accountItem}>
          <span className={styles.avatar}>{img}</span>
        </div>
      )

      userUI = (
        <div style={{ position: 'relative' }}>
          <div
            className="dropdown-action gradient-box use-avatar"
            role="button"
            tabIndex="-1"
            onKeyDown={handleKeyDown}
            onClick={(e) => {
              handleExtClick(e, 'userMenu')
            }}
          >
            {str}
          </div>
          <div
            className={`main-menu menu-sm ${isSelected ? '' : 'hidden'}`}
            style={{ width: '300px', right: '0px' }}
          >
            <ul className="munu-group">
              <li className="menu-item no-focus">
                <a>{user.email}</a>
              </li>
              {/*
              <li className="menu-item">
                <a>Name: {user.name}</a>
              </li>
              <li className="menu-item">
                <a>Role: {user.role}</a>
              </li>
              */}
              <li className="divider" />
              <li className="menu-item">
                <a href="#" onClick={() => openMain()}>
                  {i18n.t('menu.user.settings')}
                </a>
              </li>
              <li className="divider" />
              <li className="menu-item">
                <a href="#" onClick={logout}>
                  {i18n.t('menu.user.logout')}
                </a>
              </li>
            </ul>
          </div>
        </div>
      )
      console.log(user)

      mobileUserUI = (
        <React.Fragment>
          <div className="main-menu" style={{ width: '300px', right: '0px' }}>
            <ul className="munu-group">
              <li className="menu-item no-focus">
                <a>{user.email}</a>
              </li>
              {/*
              <li className="menu-item">
                <a>Name: {user.name}</a>
              </li>
              <li className="menu-item">
                <a>Role: {user.role}</a>
              </li>
              */}
              <li className="divider" />
              <li className="menu-item">
                <a href="#" onClick={(e) => openMain(e)}>
                  {i18n.t('menu.user.settings')}
                </a>
              </li>
              <li className="divider" />
            </ul>
          </div>
          <div
            role="button"
            tabIndex="-1"
            onKeyDown={handleKeyDown}
            onClick={() => {
              logout()
            }}
          >
            <div className="gradient-box">{i18n.t('menu.user.logout')}</div>
          </div>
        </React.Fragment>
      )
    } else {
      userUI = (
        <div className="gradient-box">
          {/* <Link to="/login-ui">LOGIN</Link> */}
          <div
            role="button"
            tabIndex="-1"
            onKeyDown={handleKeyDown}
            onClick={() => {
              openLogin()
            }}
          >
            {i18n.t('menu.user.login')}
          </div>
        </div>
      )

      mobileUserUI = (
        <div
          role="button"
          tabIndex="-1"
          onKeyDown={handleKeyDown}
          onClick={() => {
            openLogin()
          }}
        >
          <div className="gradient-box">{i18n.t('menu.user.login')}</div>
        </div>
      )
    }
  }

  return (
    <React.Fragment>
      <div
        className={`left-mobile-nav ${isMobileSelected ? '' : 'hidden'}`}
        role="button"
        tabIndex="-1"
        onClick={(e) => {
          mobileExtClick(e, mobileMenuKey, false)
        }}
        onKeyDown={handleKeyDown}
      >
        <div className="header">
          <Link className="brand-logo" to="/home">
            OfficeMail
          </Link>
          <a
            className="close"
            role="button"
            tabIndex="-1"
            onClick={(e) => {
              handleExtClick(e, mobileMenuKey, false)
            }}
            onKeyDown={handleKeyDown}
          >
            <img src={require('assets/ic/component/ic_close.svg').default} alt="close-icon" />
            {i18n.t('menu.user.close')}
          </a>
        </div>
        <div className="inner-box">{generateMobileMenuItems()}</div>
        <div className="bottom-box">{mobileUserUI}</div>
      </div>
      <nav className="navbar-brand" aria-label="Global navigation">
        <Link className="brand-logo" to="/home" aria-label="OfficeMail Logo">
          OfficeMail
        </Link>
        <div className="brand-menu">
          <ul className="menu-list">{generateMenuItems()}</ul>
        </div>
        {userUI}
        <div
          className="mobile-menu"
          role="button"
          tabIndex="-1"
          onClick={(e) => {
            handleExtClick(e, mobileMenuKey, true)
          }}
          onKeyDown={handleKeyDown}
        >
          {i18n.t('menu.user.menu')}
        </div>
      </nav>
    </React.Fragment>
  )
}

export default withRouter(connect(mapStateToProps)(Menu))
