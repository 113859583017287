import { ApolloClient, ApolloLink, split, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { WebSocketLink } from '@apollo/client/link/ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import store from 'store'

// eslint-disable-next-line import/no-cycle
import { customFetchWithToken } from './services/jwt'

import RestClient from './services/restClient'

import conf from './conf'

const httpLink = new HttpLink({
  uri: conf.SERVER.URL,
  fetch: customFetchWithToken,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = store.get('app.user.token')
  // return the headers to the context so httpLink can read them

  const newHeaders = {
    ...headers,
    'product-name': 'OfficeMail'
  }

  if (token != null) {
    newHeaders.authorization = `JWT ${token}`
  }

  return {
    headers: newHeaders,
  }
})

let link = null

if (process.env.REACT_APP_WEBSOCKET !== 'true') {
  link = authLink.concat(ApolloLink.from([httpLink]))
} else {
  const wsLink = new WebSocketLink(
    new SubscriptionClient(conf.SERVER.wsURL, {
      reconnect: true,
    }),
  )

  link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink,
    authLink.concat(ApolloLink.from([httpLink])),
  )
}

const cache = new InMemoryCache()
const client = new ApolloClient({
  link,
  cache,
  queryDeduplication: false,
})

export default client

const httpLinkForRefreshToken = new HttpLink({
  uri: conf.SERVER.URL,
})

const authLinkForRefreshToken = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = store.get('app.user.refreshToken')
  // return the headers to the context so httpLink can read them

  const newHeaders = {
    ...headers,
    'product-name': 'OfficeMail'
  }

  if (token != null) {
    newHeaders.authorization = `JWT ${token}`
  }

  return {
    headers: newHeaders,
  }
})

const linkForRefreshToken = authLinkForRefreshToken.concat(
  ApolloLink.from([httpLinkForRefreshToken]),
)

const cacheForRefreshToken = new InMemoryCache()

export const clientForRefreshToken = new ApolloClient({
  link: linkForRefreshToken,
  cache: cacheForRefreshToken,
  queryDeduplication: false,
})

export const restClient = new RestClient(conf.SERVER.restURL, fetch, false)

export const restClientWithToken = new RestClient(conf.SERVER.restURL, customFetchWithToken, true)
